<template>
  <a-spin :style="backCss" class="touch-spin" :spinning="loading">
    <div class="flex mb-4">
      <div class="flex-1">
        <a-radio-group default-value="0" button-style="solid" v-model="filterValue">
          <a-radio-button value="0"> 所有 </a-radio-button>
          <a-radio-button value="1"> 等待 </a-radio-button>
          <a-radio-button value="2"> 可开始 </a-radio-button>
          <a-radio-button value="3"> 已完成 </a-radio-button>
          <a-radio-button value="4"> 驳回 </a-radio-button>
        </a-radio-group>
        <a-button style="margin-left:10px;position: relative;top:-1px;" v-if="isEdit" @click="cancel(false)"
          >取消</a-button
        >
        <a-button
          v-if="!isMyTask && checkPermission() && parent.isTongChou"
          type="primary"
          @click="enterEdit"
          :disabled="eidtDis"
          style="margin-left:10px;position: relative;top:-1px;"
        >
          {{ isEdit == true ? '保存' : '编辑' }}
        </a-button>
      </div>
    </div>
    <div>
      <ul class="card-row header-row" style="margin-bottom:10px;">
        <!-- <li class="label">章节</li> -->
        <li class="card-col" v-for="(item, idx) in kanbanData.stages" :key="idx">{{ item.stageName }}</li>
      </ul>
      <div v-if="isMyTask" style="display: flex">
        <ul class="card-column" v-for="(col, idx) in kanbanData.stages" :key="idx">
          <li class="card-col" v-for="(item, index) in col.tasks" :key="index">
            <div
              class="k-card"
              :style="getBackColor(item)"
              v-if="item.taskId && filterCondition(item)"
              @click="gotoDetail(item)"
            >
              <div class="items-center mb-3">
                <div class="flex-1 font-medium">
                  <div>
                    <a-checkbox
                      v-if="isSelect"
                      v-model="item.checked"
                      @click.stop
                      @change="
                        e => {
                          onChange(e, item)
                        }
                      "
                      :disabled="isDis(item, col.tasks)"
                    >
                      {{ item.productionName }}
                    </a-checkbox>
                    <a-tooltip v-else placement="top">
                      <template slot="title">
                        <span style="color:rgba(0, 0, 0, 0.65)">{{ item.productionName }}</span>
                      </template>
                      <div style="white-space: nowrap; overflow: hidden;text-overflow: ellipsis;width:70% ">
                        {{ item.productionName }}
                      </div>
                    </a-tooltip>
                  </div>
                  <div>{{ parseFloat((item.chapterSequenceNo - 0).toFixed(1)) }}</div>
                  <div v-if="isEdit" @click.stop>
                    <a-select showArrow placeholder="选择用户" v-model="item.user.userId">
                      <a-select-option v-for="user in allUsers" :key="user.userId" :value="user.userId">
                        {{ user.userName }}
                      </a-select-option>
                    </a-select>
                  </div>
                  <div v-else class="kanban-span" style="display: flex;align-items: center;">
                    <img
                      style="width:15px;height:15px;border-radius:50%;margin-right:3px;"
                      :src="item.user.avatar"
                      alt=""
                    />
                    {{ item.user ? item.user.userName : '' }}
                  </div>
                </div>
                <actions-tasks
                  style="position: absolute;top: 9px;right: 9px;"
                  :propsParams="item"
                  :loadData="reloadData"
                  :taskList="col.taskList"
                ></actions-tasks>
              </div>
              <div v-if="isEdit" @click.stop>
                <a-date-picker
                  style="font-size:12px"
                  format="YYYY-MM-DD HH:mm"
                  valueFormat="YYYY-MM-DD HH:mm"
                  :show-time="{ format: 'HH:mm' }"
                  placeholder="请选择截止日期"
                  v-model="item.deadline"
                />
              </div>
              <div
                v-else
                class="text-xs tc-light"
                :style="
                  item.warningState == '拖稿' && item.taskStatus != '已完成'
                    ? 'color: red !important;display:flex;flex-wrap:wrap'
                    : 'display:flex;flex-wrap:wrap'
                "
              >
                <div style="padding-right:5px;">{{ item.deadline && item.deadline.slice(0, 10) }}</div>
                <div>{{ item.deadline && item.deadline.slice(11, 16) }}</div>
              </div>
              <!-- <div class="text-xs tc-light">完成日期：{{ item.endTime ? item.endTime.substring(0, 10) : '' }}</div> -->
            </div>
          </li>
        </ul>
      </div>
      <div v-else class="scrollx-wrap" ref="scrollxWrap">
        <template v-if="total != 0">
          <ul class="card-row" v-for="(row, idx) in kanbanData.chapters" :key="idx">
            <!-- <li class="label">{{ row.sequenceNo }}</li> -->
            <li class="card-col" v-for="(item, index) in row.taskList" :key="index">
              <div class="k-card" :style="getBackColor(item)" v-if="item.taskId && filterCondition(item)">
                <div class="flex items-center mb-3" style="margin-bottom: 0.3rem !important;">
                  <div class="flex-1 font-medium">
                    <div>
                      <a-checkbox
                        v-if="isSelect"
                        v-model="item.checked"
                        @click.stop
                        @change="
                          e => {
                            onChange(e, item)
                          }
                        "
                        :disabled="isDis(item, row.taskList)"
                      >
                        {{ item.productionName }}
                      </a-checkbox>
                      <a-tooltip v-else placement="top">
                        <template slot="title">
                          <span style="color:rgba(0, 0, 0, 0.65)">{{ item.productionName }}</span>
                        </template>
                        <div style="white-space: nowrap; overflow: hidden;text-overflow: ellipsis;width:70% ">
                          <span class="kanban-span" @click="gotoDetail(item)">{{ item.productionName }}</span>
                        </div>
                      </a-tooltip>
                    </div>
                    <div>
                      <span class="kanban-span" @click="gotoDetail(item)">{{
                        parseFloat((item.chapterSequenceNo - 0).toFixed(1))
                      }}</span>
                    </div>
                    <div v-if="isEdit" @click.stop>
                      <a-select showArrow placeholder="选择用户" v-model="item.user.userId">
                        <a-select-option
                          v-for="user in getSelectUser(allUsers, item)"
                          :key="user.userId"
                          :value="user.userId"
                        >
                          {{ user.userName }}
                        </a-select-option>
                      </a-select>
                    </div>
                    <div
                      v-else
                      class="kanban-span"
                      @click="gotoDetail(item)"
                      style="display: flex;align-items: center;margin-top:2px;"
                    >
                      <img
                        style="width:20px;height:20px;border-radius:50%;margin-right:3px;"
                        :src="item.user.avatar"
                        alt=""
                      />
                      {{ item.user ? item.user.userName : '' }}
                    </div>
                  </div>
                  <actions-tasks
                    style="position: absolute;top: 9px;right: 9px;"
                    :propsParams="item"
                    :loadData="reloadData"
                    :taskList="row.taskList"
                  ></actions-tasks>
                </div>
                <div v-if="isEdit" @click.stop>
                  <a-date-picker
                    class="kanban-date"
                    style="font-size:12px;"
                    format="YYYY-MM-DD HH:mm"
                    valueFormat="YYYY-MM-DD HH:mm"
                    :show-time="{ format: 'HH:mm', defaultValue: moment('23:59', 'HH:mm') }"
                    placeholder="请选择截止日期"
                    v-model="item.deadline"
                  />
                </div>
                <div
                  v-else
                  class="text-xs tc-light"
                  :style="
                    item.warningState == '拖稿' && item.taskStatus != '已完成'
                      ? 'color: red !important;display:flex;flex-wrap:wrap'
                      : 'display:flex;flex-wrap:wrap'
                  "
                >
                  <div @click="gotoDetail(item)" style="padding-right:5px;">
                    {{ item.deadline && item.deadline.slice(0, 10) }}
                  </div>
                  <div @click="gotoDetail(item)">{{ item.deadline && item.deadline.slice(11, 16) }}</div>
                </div>
                <!-- <div class="text-xs tc-light">完成日期：{{ item.endTime ? item.endTime.substring(0, 10) : '' }}</div> -->
              </div>
            </li>
          </ul>
        </template>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import ActionsTasks from '@/views/system/modules/mytasks/actionsTasks'
import { checkPermission } from '@/utils/hasPermission'
import moment from 'moment'
export default {
  components: {
    ActionsTasks
  },
  props: {
    isMyTask: {
      type: Boolean,
      default: false
    },
    queryParam: {
      type: Object,
      default: () => {}
    },
    selectKeys: {
      type: Array,
      default: () => {
        ;[]
      }
    },
    allUsers: {
      type: Array,
      default: () => {
        []
      }
    },
    tab: {
      type: String,
      default: '1'
    },
    parent: {
      type: Object,
      default: () => {}
    }
  },
  watch: {},
  data() {
    return {
      filterValue: '0',
      kanbanData: {
        chapters: []
      },
      isSelect: false,
      isEdit: false,
      oldKanbanData: null,
      loading: false,
      eidtDis: false,
      kanbanWidth: '',
      page: 1,
      total: 0
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  methods: {
    moment,
    batchSelect() {
      this.isSelect = !this.isSelect
      this.eidtDis = !this.eidtDis
      if (!this.isMyTask) {
        this.kanbanData.chapters.forEach(row => {
          row.taskList.forEach(item => {
            item.checked = false
          })
        })
      }
      this.$emit('setSelectKeys')
    },
    checkPermission() {
      return checkPermission('production:task:button:edit')
    },
    getSelectUser(users, item) {
      let arr = []
      users.forEach(user => {
        for (let key in user.positions) {
          if (
            item.stageList.some(stage => {
              return stage.positionName == user.positions[key].positionName && stage.stageName == item.stageName
            })
          ) {
            arr.push(user)
            break
          }
        }
      })
      users = arr
      return users
    },
    getBackColor(item) {
      let color = 'background-color:#fff'
      if (item) {
        if (item.taskStatus == '驳回') {
          color =
            item.deadline && item.deadline.slice(11, 16) < '18'
              ? 'background-color:rgb(255 225 225);border: 2px solid #ffa39e;'
              : item.priority == 'HIGH'
              ? 'background-color:rgb(255 225 225);border: 2px solid #ffa39e;'
              : item.finishStatus == 'DELAY'
              ? 'background-color:#fff9b7'
              : item.hasBeenReject
              ? 'background-color:rgb(255 229 199)'
              : 'background-color:#fff;'
        } else if (item.taskStatus == '已完成') {
          color =
            item.deadline && item.deadline.slice(11, 16) < '18' && item.finishStatus != 'DELAY'
              ? '#fff;'
              : item.priority == 'HIGH' && item.finishStatus != 'DELAY'
              ? '#fff;'
              : item.finishStatus == 'DELAY'
              ? 'background-color:#fff9b7;'
              : 'background-color:#fff;'
        } else if (item.taskStatus == '可开始' && item.hasBeenReject) {
          color = 'background-color:rgb(255 229 199);'
        }
        if (item.finishStatus == 'DELAY') {
          color = 'background-color:#fff9b7;'
        }
      }
      if(item.rejectTaskId&&item.taskStatus != '驳回'){
        color = color+"border: 2px solid orange;"
      }
      return color
    },
    cancel(isChangeTab) {
      let self = this
      if (this.oldKanbanData) {
        if (JSON.stringify(this.oldKanbanData) != JSON.stringify(this.kanbanData)) {
          this.$confirm({
            title: '提示',
            content: '您尚未保存数据，需要保存吗？',
            async onOk() {
              const dataList = self.kanbanData.chapters.flatMap(chapter => {
                const { taskList } = chapter
                taskList.map(item => {
                  if (item.deadline != null) {
                    item.deadline = item.deadline + ''
                    if (item.deadline.length <= 17) item.deadline = item.deadline + ':00'
                  }
                  item.ownerId = item.user == null ? null : item.user.userId
                  delete item.priority
                  delete item.taskList
                  delete item.taskStatus
                })
                return taskList
              })
              const res = await postAction('/tasks/batch/updateTask', dataList)
              if (res.code == 200) {
                self.$message.success('保存成功')
                self.kanbanData = {
                  chapters: []
                }
                self.page = 1
              } else {
                self.$message.error(res.msg)
              }
              self.reloadData()
              self.isEdit = false
              if (!self.isMyTask) {
                self.$emit('setBatchDis', false)
              }
            },
            onCancel() {
              self.reloadData()
              if (isChangeTab) {
                self.$emit('changeTab')
              }
              self.isEdit = false
              if (!self.isMyTask) {
                self.$emit('setBatchDis', false)
              }
            }
          })
        } else {
          if (isChangeTab) {
            self.$emit('changeTab')
          }
          this.isEdit = false
        }
      }
      if (!this.isMyTask) {
        this.$emit('setBatchDis', false)
      }
    },
    async enterEdit() {
      if (this.isEdit) {
        let dataList = []
        if (!this.isMyTask) {
          dataList = this.kanbanData.chapters.flatMap(chapter => {
            const { taskList } = chapter
            taskList.forEach(item => {
              if (item.deadline != null) {
                item.deadline = item.deadline + ''
                if (item.deadline.length <= 17) item.deadline = item.deadline + ':00'
              }
              item.ownerId = item.user == null ? '' : item.user.userId
              delete item.priority
              delete item.taskList
              delete item.taskStatus
            })
            return taskList
          })
        } else {
          dataList = this.kanbanData.stages.flatMap(chapter => {
            let { tasks } = chapter
            tasks.forEach(item => {
              if (item.deadline != null) {
                item.deadline = item.deadline + ''
                if (item.deadline.length <= 17) item.deadline = item.deadline + ':00'
              }
              item.ownerId = item.user == null ? '' : item.user.userId
              delete item.priority
              delete item.taskList
              delete item.taskStatus
            })
            return tasks
          })
        }
        const res = await postAction('/tasks/batch/updateTask', dataList)
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.kanbanData = {
            chapters: []
          }
          this.page = 1
          this.isEdit = false
          this.oldKanbanData = JSON.parse(JSON.stringify(this.kanbanData))
        } else {
          this.$message.error(res.msg || res.message)
        }
        this.reloadData()
      } else {
        this.isEdit = true
        if (!this.isMyTask) {
          this.$emit('setBatchDis', true)
        }
        this.oldKanbanData = JSON.parse(JSON.stringify(this.kanbanData))
      }
    },
    isDis(propsParams, taskList) {
      let isDis = false
      taskList?.forEach((item, index) => {
        if (item.id == propsParams.id && index != 0) {
          if (taskList[index - 1].taskStatus === '驳回' && propsParams.taskStatus === '驳回') {
            isDis = true
          }
        }
      })
      if (propsParams.taskStatus === '等待' && propsParams.taskName != '给稿') {
        return true
      } else {
        if (propsParams.taskStatus === '已完成') {
          return true
        } else {
          if (this.selectKeys.length > 0) {
            if (
              this.selectKeys[0].stageName != propsParams.stageName ||
              propsParams.taskStatus != this.selectKeys[0].taskStatus
            ) {
              return true
            } else {
              return isDis
            }
          } else {
            return isDis
          }
        }
      }
    },
    onChange(e, data) {
      this.$emit('getKey', { checked: e.target.checked, ...data })
    },
    getKanBanData() {
      if (this.loading) {
        return
      }
      this.loading = true
      if (!this.isMyTask) {
        getAction(`/production/list_kanban?id=${this.$route.params.id}&current=${this.page}&size=5`).then(res => {
          console.log(res)
          this.kanbanData.stages = res.data.stages
          if (this.kanbanData.chapters.length) {
            if (res.data.chapters) {
              if (
                res.data.chapters.every(item => {
                  return item.chapterId != this.kanbanData.chapters[0].chapterId
                })
              ) {
                this.kanbanData.chapters = [...this.kanbanData.chapters, ...res.data.chapters]
              } else {
                this.kanbanData.chapters = res.data.chapters
              }
            }
          } else {
            this.kanbanData.chapters = res.data.chapters
          }
          this.total = res.data.total
          this.kanbanData.stages?.forEach((item, index) => {
            this.kanbanData.chapters.forEach(i => {
              if (
                i.taskList.every(t => {
                  return item.stageId != t.stageId
                })
              ) {
                i.taskList.splice(index, 0, {})
              }
            })
          })
          setTimeout(() => {
            this.kanbanWidth = document.getElementsByClassName('header-row')[0].childNodes.length * 180 + 'px'
            document.getElementsByClassName('scrollx-wrap')[0].style.width = this.kanbanWidth
          }, 0)
          this.loading = false
        })
      } else {
        getAction('/tasks/my_kanban', this.queryParam).then(res => {
          this.kanbanData = res.data
          this.kanbanData.columns = []
          for (let idx = 0; idx < this.kanbanData.stages.length; idx++) {
            let column = []
            this.kanbanData.chapters?.forEach(one => {
              column.push(one.taskList[idx])
            })
            this.kanbanData.columns.push(column)
          }
          this.loading = false
        })
      }
    },
    reloadData() {
      this.getKanBanData()
    },
    filterCondition(item) {
      if (this.filterValue == 0) {
        return true
      } else if (this.filterValue == 1) {
        if (item.taskStatus == '等待') {
          return true
        }
      } else if (this.filterValue == 2) {
        if (item.taskStatus == '可开始') {
          return true
        }
      } else if (this.filterValue == 3) {
        if (item.taskStatus == '已完成') {
          return true
        }
      } else if (this.filterValue == 4) {
        if (item.taskStatus == '驳回') {
          return true
        }
      }
      return false
    },
    gotoDetail(item) {
      if (this.selectKeys.length > 0) return
      if (this.isEdit) return
      item.id = item.taskId
      console.log(item)
      if (this.isMyTask) {
        this.$router.push({
          path: `/translate/mytasks/detail/${item.taskId}/${item.productionId}/${
            item.chapterId
          }?record=${encodeURIComponent(JSON.stringify({ platformId: item.platformId }))}`
        })
      } else {
        this.$router.push({
          path: `/translate/mytasks/detail/${item.taskId}/${item.productionId}/${
            item.chapterId
          }?fromProduction=1&record=${encodeURIComponent(
            JSON.stringify({
              platformId: item.platformId,
              productionName: item.productionName,
              departmentIds: this.parent.departmentIds
            })
          )}`
        })
      }
    },
    lazyLoading(e) {
      const scrollTop = e.target.scrollTop // 滚动条滚动时，距离顶部的距离
      const windowHeight = e.target.clientHeight // 可视区的高度
      const scrollHeight = e.target.scrollHeight // 滚动条的总高度
      // 滚动条到底部
      if (scrollTop + windowHeight + 50 >= scrollHeight) {
        this.page++
        if (this.kanbanData.chapters.length >= this.total) return
        this.getKanBanData()
      }
    }
  },
  created() {
    /* this.getKanBanData() */
    this.kanbanData.chapters = []
  },
  mounted() {
    this.$refs.scrollxWrap.addEventListener('scroll', this.lazyLoading)
    document.addEventListener(
      'touchmove',
      function(e) {
        /* e.preventDefalut() */
      },
      {
        passive: true
      }
    )

    var startX, startY
    document.addEventListener('touchstart', function(e) {
      startX = e.targetTouches[0].pageX
      startY = e.targetTouches[0].pageY
    })

    document.addEventListener(
      'touchmove',
      function(e) {
        var moveX = e.targetTouches[0].pageX
        var moveY = e.targetTouches[0].pageY

        if (Math.abs(moveX - startX) > Math.abs(moveY - startY)) {
          /* e.preventDefault() */
        }
      },
      {
        passive: true
      }
    )
  }
}
</script>

<style lang="less" scoped>
.touch-spin {
  touch-action: pan-y !important;
  touch-action: pan-x !important;
}
/deep/ .kanban-date .ant-calendar-picker-input {
  font-size:12px !important;
}
.kanban-span:hover {
  color: VAR(--theme-color);
  cursor: pointer;
}
.scrollx-wrap {
  min-width: 100%;
  overflow-y: overlay;
  max-height: 55.5vh;
}
.card-column {
  list-style: none;
  display: flex;
  padding: 10px 0;
  margin: 0;
  flex-direction: column;
}
.card-row {
  list-style: none;
  display: flex;
  padding: 10px 0;
  margin: 0;
}
.card-row,
.card-column {
  .label {
    width: 40px;
  }
  .card-col {
    min-width: 180px;
    padding: 0 10px;
  }
  &.header-row {
    font-weight: bold;
    color: #333;
    border-bottom: 1px solid #eee;
    // margin-bottom: 10px;
  }
  .k-card {
    background-color: #fcfcfc;
    border-radius: 4px;
    box-shadow: 1px 1px 2px rgba(#000, 0.1), -1px 0 1px rgba(#000, 0.06);
    padding: 10px;
    position: relative;
    .flex {
      align-items: flex-start;
    }
    .dot {
      width: 5px;
      height: 5px;
      border-radius: 10px;
    }
    .delay {
      color: red;
    }
  }
}
/* .header-row{
  .card-col {
    width: 200px;
    padding: 0 10px;
  }
} */
.card-column .k-card {
  margin-bottom: 10px;
}
.ant-calendar-picker {
  min-width: 140px !important;
}
.ant-calendar-picker-input {
  font-size:12px !important;
}
</style>
