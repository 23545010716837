var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    {
      staticClass: "touch-spin",
      style: _vm.backCss,
      attrs: { spinning: _vm.loading },
    },
    [
      _c("div", { staticClass: "flex mb-4" }, [
        _c(
          "div",
          { staticClass: "flex-1" },
          [
            _c(
              "a-radio-group",
              {
                attrs: { "default-value": "0", "button-style": "solid" },
                model: {
                  value: _vm.filterValue,
                  callback: function ($$v) {
                    _vm.filterValue = $$v
                  },
                  expression: "filterValue",
                },
              },
              [
                _c("a-radio-button", { attrs: { value: "0" } }, [
                  _vm._v(" 所有 "),
                ]),
                _c("a-radio-button", { attrs: { value: "1" } }, [
                  _vm._v(" 等待 "),
                ]),
                _c("a-radio-button", { attrs: { value: "2" } }, [
                  _vm._v(" 可开始 "),
                ]),
                _c("a-radio-button", { attrs: { value: "3" } }, [
                  _vm._v(" 已完成 "),
                ]),
                _c("a-radio-button", { attrs: { value: "4" } }, [
                  _vm._v(" 驳回 "),
                ]),
              ],
              1
            ),
            _vm.isEdit
              ? _c(
                  "a-button",
                  {
                    staticStyle: {
                      "margin-left": "10px",
                      position: "relative",
                      top: "-1px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.cancel(false)
                      },
                    },
                  },
                  [_vm._v("取消")]
                )
              : _vm._e(),
            !_vm.isMyTask && _vm.checkPermission() && _vm.parent.isTongChou
              ? _c(
                  "a-button",
                  {
                    staticStyle: {
                      "margin-left": "10px",
                      position: "relative",
                      top: "-1px",
                    },
                    attrs: { type: "primary", disabled: _vm.eidtDis },
                    on: { click: _vm.enterEdit },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.isEdit == true ? "保存" : "编辑") +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", [
        _c(
          "ul",
          {
            staticClass: "card-row header-row",
            staticStyle: { "margin-bottom": "10px" },
          },
          _vm._l(_vm.kanbanData.stages, function (item, idx) {
            return _c("li", { key: idx, staticClass: "card-col" }, [
              _vm._v(_vm._s(item.stageName)),
            ])
          }),
          0
        ),
        _vm.isMyTask
          ? _c(
              "div",
              { staticStyle: { display: "flex" } },
              _vm._l(_vm.kanbanData.stages, function (col, idx) {
                return _c(
                  "ul",
                  { key: idx, staticClass: "card-column" },
                  _vm._l(col.tasks, function (item, index) {
                    return _c("li", { key: index, staticClass: "card-col" }, [
                      item.taskId && _vm.filterCondition(item)
                        ? _c(
                            "div",
                            {
                              staticClass: "k-card",
                              style: _vm.getBackColor(item),
                              on: {
                                click: function ($event) {
                                  return _vm.gotoDetail(item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "items-center mb-3" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex-1 font-medium" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm.isSelect
                                            ? _c(
                                                "a-checkbox",
                                                {
                                                  attrs: {
                                                    disabled: _vm.isDis(
                                                      item,
                                                      col.tasks
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                    },
                                                    change: (e) => {
                                                      _vm.onChange(e, item)
                                                    },
                                                  },
                                                  model: {
                                                    value: item.checked,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "checked",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.checked",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        item.productionName
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "a-tooltip",
                                                { attrs: { placement: "top" } },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "title" },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color:
                                                              "rgba(0, 0, 0, 0.65)",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.productionName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "white-space": "nowrap",
                                                        overflow: "hidden",
                                                        "text-overflow":
                                                          "ellipsis",
                                                        width: "70%",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            item.productionName
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                        ],
                                        1
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            parseFloat(
                                              (
                                                item.chapterSequenceNo - 0
                                              ).toFixed(1)
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm.isEdit
                                        ? _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showArrow: "",
                                                    placeholder: "选择用户",
                                                  },
                                                  model: {
                                                    value: item.user.userId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item.user,
                                                        "userId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.user.userId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.allUsers,
                                                  function (user) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: user.userId,
                                                        attrs: {
                                                          value: user.userId,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              user.userName
                                                            ) +
                                                            "\n                    "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "kanban-span",
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "15px",
                                                  height: "15px",
                                                  "border-radius": "50%",
                                                  "margin-right": "3px",
                                                },
                                                attrs: {
                                                  src: item.user.avatar,
                                                  alt: "",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    item.user
                                                      ? item.user.userName
                                                      : ""
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                  _c("actions-tasks", {
                                    staticStyle: {
                                      position: "absolute",
                                      top: "9px",
                                      right: "9px",
                                    },
                                    attrs: {
                                      propsParams: item,
                                      loadData: _vm.reloadData,
                                      taskList: col.taskList,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isEdit
                                ? _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _c("a-date-picker", {
                                        staticStyle: { "font-size": "12px" },
                                        attrs: {
                                          format: "YYYY-MM-DD HH:mm",
                                          valueFormat: "YYYY-MM-DD HH:mm",
                                          "show-time": { format: "HH:mm" },
                                          placeholder: "请选择截止日期",
                                        },
                                        model: {
                                          value: item.deadline,
                                          callback: function ($$v) {
                                            _vm.$set(item, "deadline", $$v)
                                          },
                                          expression: "item.deadline",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "text-xs tc-light",
                                      style:
                                        item.warningState == "拖稿" &&
                                        item.taskStatus != "已完成"
                                          ? "color: red !important;display:flex;flex-wrap:wrap"
                                          : "display:flex;flex-wrap:wrap",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "padding-right": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.deadline &&
                                                item.deadline.slice(0, 10)
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            item.deadline &&
                                              item.deadline.slice(11, 16)
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                )
              }),
              0
            )
          : _c(
              "div",
              { ref: "scrollxWrap", staticClass: "scrollx-wrap" },
              [
                _vm.total != 0
                  ? _vm._l(_vm.kanbanData.chapters, function (row, idx) {
                      return _c(
                        "ul",
                        { key: idx, staticClass: "card-row" },
                        _vm._l(row.taskList, function (item, index) {
                          return _c(
                            "li",
                            { key: index, staticClass: "card-col" },
                            [
                              item.taskId && _vm.filterCondition(item)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "k-card",
                                      style: _vm.getBackColor(item),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex items-center mb-3",
                                          staticStyle: {
                                            "margin-bottom":
                                              "0.3rem !important",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex-1 font-medium",
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _vm.isSelect
                                                    ? _c(
                                                        "a-checkbox",
                                                        {
                                                          attrs: {
                                                            disabled: _vm.isDis(
                                                              item,
                                                              row.taskList
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            },
                                                            change: (e) => {
                                                              _vm.onChange(
                                                                e,
                                                                item
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value: item.checked,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "checked",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.checked",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                item.productionName
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "a-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "rgba(0, 0, 0, 0.65)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.productionName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "white-space":
                                                                  "nowrap",
                                                                overflow:
                                                                  "hidden",
                                                                "text-overflow":
                                                                  "ellipsis",
                                                                width: "70%",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "kanban-span",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.gotoDetail(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.productionName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                ],
                                                1
                                              ),
                                              _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "kanban-span",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.gotoDetail(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        parseFloat(
                                                          (
                                                            item.chapterSequenceNo -
                                                            0
                                                          ).toFixed(1)
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _vm.isEdit
                                                ? _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          attrs: {
                                                            showArrow: "",
                                                            placeholder:
                                                              "选择用户",
                                                          },
                                                          model: {
                                                            value:
                                                              item.user.userId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item.user,
                                                                "userId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.user.userId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.getSelectUser(
                                                            _vm.allUsers,
                                                            item
                                                          ),
                                                          function (user) {
                                                            return _c(
                                                              "a-select-option",
                                                              {
                                                                key: user.userId,
                                                                attrs: {
                                                                  value:
                                                                    user.userId,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      user.userName
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kanban-span",
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                        "margin-top": "2px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.gotoDetail(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          width: "20px",
                                                          height: "20px",
                                                          "border-radius":
                                                            "50%",
                                                          "margin-right": "3px",
                                                        },
                                                        attrs: {
                                                          src: item.user.avatar,
                                                          alt: "",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            item.user
                                                              ? item.user
                                                                  .userName
                                                              : ""
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                          _c("actions-tasks", {
                                            staticStyle: {
                                              position: "absolute",
                                              top: "9px",
                                              right: "9px",
                                            },
                                            attrs: {
                                              propsParams: item,
                                              loadData: _vm.reloadData,
                                              taskList: row.taskList,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.isEdit
                                        ? _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                },
                                              },
                                            },
                                            [
                                              _c("a-date-picker", {
                                                staticClass: "kanban-date",
                                                staticStyle: {
                                                  "font-size": "12px",
                                                },
                                                attrs: {
                                                  format: "YYYY-MM-DD HH:mm",
                                                  valueFormat:
                                                    "YYYY-MM-DD HH:mm",
                                                  "show-time": {
                                                    format: "HH:mm",
                                                    defaultValue: _vm.moment(
                                                      "23:59",
                                                      "HH:mm"
                                                    ),
                                                  },
                                                  placeholder: "请选择截止日期",
                                                },
                                                model: {
                                                  value: item.deadline,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "deadline",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.deadline",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "text-xs tc-light",
                                              style:
                                                item.warningState == "拖稿" &&
                                                item.taskStatus != "已完成"
                                                  ? "color: red !important;display:flex;flex-wrap:wrap"
                                                  : "display:flex;flex-wrap:wrap",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "padding-right": "5px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.gotoDetail(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        item.deadline &&
                                                          item.deadline.slice(
                                                            0,
                                                            10
                                                          )
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.gotoDetail(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.deadline &&
                                                        item.deadline.slice(
                                                          11,
                                                          16
                                                        )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    })
                  : _vm._e(),
              ],
              2
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }