import { render, staticRenderFns } from "./panel-kanban.vue?vue&type=template&id=83d0107c&scoped=true&"
import script from "./panel-kanban.vue?vue&type=script&lang=js&"
export * from "./panel-kanban.vue?vue&type=script&lang=js&"
import style0 from "./panel-kanban.vue?vue&type=style&index=0&id=83d0107c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83d0107c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jobs/management-system-frontend/workspace/ant-design-vue-jeecg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('83d0107c')) {
      api.createRecord('83d0107c', component.options)
    } else {
      api.reload('83d0107c', component.options)
    }
    module.hot.accept("./panel-kanban.vue?vue&type=template&id=83d0107c&scoped=true&", function () {
      api.rerender('83d0107c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/modules/panel-kanban.vue"
export default component.exports